:root {
    --app-height: 100lvh;
}

.sidePanel {
    position: fixed;
    background-color: var(--white);
    color: var(--black);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease;
    z-index: 1000;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    opacity: 0;
}

.sidePanel.left {
    left: 0;
    top: 0;
    bottom: 0;
    transform: translateX(-100%);
}

.sidePanel.right {
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(100%);
}

.sidePanel.top {
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-100%);
}

.sidePanel.bottom {
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
}

.sidePanel.open {
    transform: translateX(0);
    transform: translateY(0);
    opacity: 1;
}

.toggleButton {
    position: fixed;
    cursor: pointer;
    border: none;
    background: none;
    filter: drop-shadow(0.3px 0px 0.05px black);
    z-index: 999;
}

.toggleButton.left img {
    height: 50px;
    transform: rotate(180deg);
}

.toggleButton.left {
    left: 10px;
    top: calc(var(--app-height) / 2);
    transform: translateY(-50%);
}

.toggleButton.right img {
    height: 50px;
}

.toggleButton.right {
    right: 10px;
    top: 50vh;
    transform: translateY(-50%);
}

.toggleButton.top img {
    height: 50px;
    transform: rotate(270deg);
}

.toggleButton.top {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.toggleButton.bottom img {
    height: 50px;
    transform: rotate(90deg);
}

.toggleButton.bottom {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.mobileButton {
    mix-blend-mode: difference;
}

.closeButton {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    z-index: 1100;
}

.closeButton.left img {
    height: 50px;
}

.closeButton.left {
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
}

.closeButton.right img {
    height: 50px;
    transform: rotate(180deg);
}

.closeButton.right {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.closeButton.top img {
    height: 50px;
    transform: rotate(90deg);
}

.closeButton.top {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.closeButton.bottom img {
    height: 50px;
    transform: rotate(270deg);
}

.closeButton.bottom {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.content {
    flex: 1;
    padding: 20px;
}


@media (max-width: 430px) {
    .closeButton.left img,
    .closeButton.right img,
    .closeButton.top img,
    .closeButton.bottom img 
    {
        height: 35px;
    }

    .toggleButton.left {
        left: 4px;
    }

    .toggleButton.left img {
        height: 35px;

    }
    .content {
        padding: var(--margin-top-header);
    }
}