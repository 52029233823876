.wrapper {
    margin-left: calc(var(--header-margin) - 0.2rem);
    margin-right: calc(var(--header-margin) - 0.2rem);
}

.cardWrapper {
    margin-top: 1.4rem;
}

.description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: auto;
    min-height: 40px;
}

.description p {
    font-size: 0.7rem;
}

.customer, .tags {
    width: 25%;
}

.customer {
    font-weight: bold;
    text-align: left;
}

.name {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); 
    text-align: center; 
}

.tags {
    text-align: right;
    color: var(--grey);
}

.mediaWrapper {
    height: 14rem;
}
