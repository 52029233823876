.videoWrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.spinnerWrapper {
    position: absolute;
    margin-top: 50vh;
    left: 50%; 
    transform: translate(-50%, -50%);
}

.videoDesktop {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 56.25vw; 
    min-height: 100%;
    min-width: 177.77vh; 
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.videoMobile {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.videoWorkList {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.controlsContainer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    left: 0;
    right: 0;
    bottom: 4.2rem;
    margin: auto;
    width: 100%;
    z-index: 10000;
}

.controlsDesktop {
    color: white;
    filter: drop-shadow(0.3px 0px 0.1px black);
}

.controlsDesktop svg {
    cursor: pointer;
}

.controlsMobile {
    mix-blend-mode: difference;
    left: calc(95% - var(--margin-header));
    bottom: 0.5rem;
}

.controlsMobile svg {
    cursor: pointer;
}

.fullscreen {
    width: 100vw !important;
    height: 100vh !important;
    background: black;
}

.fullscreenWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.muteIcon {
    transform: scale(1.5);
    position: relative;
    bottom: -8px;
    left: -6px;
    cursor: pointer;
}

.spinnerContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
}

.spinnerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media screen and (orientation: landscape) {
    .fullscreen iframe {
        width: 100vw;
        height: 100vh;
        object-fit: contain;
    }
}

