:root {
    --header-margin: 2rem;
    --transition-speed: 0.3s;
    --panel-text-color: grey;
    --header-margin: 2rem;
    --transition-speed: 0.3s;
    --panel-text-color: grey;
}

.dragContainer {
    touch-action: pan-y; /* Disable all touch gestures */
    -webkit-user-select: none; /* Prevent text selection during dragging */
    user-select: none;
}

.exit {
    opacity: 0; /* Fade out */
    transform: translateY(20px); /* Slide down */
    transition: opacity var(--transition-speed), transform var(--transition-speed);
}

.pageContainer {
    min-height: 100vh;
    width: 100%;
    position: relative;
}

.page {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-color: var(--black);
    transition: transform var(--transition-speed) ease-in-out;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
}

@media (min-width: 960px) {
    .page {
        background-color: var(--black);
    }
}

.creditsWrapper {
    margin-top: 0.8rem;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.opened, .closed {
    height: 25px;
    position: relative;
    top: 2px
}

.opened {
    transform: rotate(90deg);
}

.closed {
    transform: rotate(270deg);
} 

.toggleCreditsButton{
    cursor: pointer;
    text-decoration: underline;
}

.toggleCreditsButton p {
    display: flex;
}

.creditsWrapper.fadeOut {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease, transform 0.5s ease; 
}

.fadeIn {
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
    animation-delay: calc(var(--child-index, 0) * 0.05s); 
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.background {
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.background video,
.videoBackground {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
}

.fullScreenIcon {
    position: fixed;
    bottom: 125px;
    right: 35px;
    cursor: pointer;
    z-index: 100;
    mix-blend-mode: var(--mix-bm);
}

.navigationWrapper {
    display: flex;
    justify-content: flex-end;
    margin: var(--header-margin);
}

.shadow-wrapper {
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.previousProject {
    margin-right: 40px;
}

.navigationImg {
    height: 25px;
    filter: drop-shadow(0.3px 0px 0.1px black);
    cursor: pointer;
    
}



.sidePanelWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.panelTitle {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: larger;
    margin-bottom: 5px;
}

.projectName {
    max-width: 70%;
    text-align: right;
}

.panelUnderTitle {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: medium;
    color: var(--panel-text-color);
    margin-bottom: 20px;
}

.sidePanelTopWrapper {
    min-height: 20%;
}

.description,
.credits {
    margin-right: 18px;
    font-weight: 0;
}

.description {
    font-size: 0.9rem;
    margin-bottom: 1.2rem;
    text-align: justify;
}

.visibleMembers {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.visibleMembers span{
    font-weight: bold;
}

.credits {
    line-height: 1.5;
}

h3 {
    font-size: medium;
}

.secondPart {
    position: absolute;
    bottom: 0;
    height: 140px;
    margin-bottom: -80px;
    width: 100%;
    transition: all var(--transition-speed) ease;
}

.secondPart:hover {
    height: 155px;
}

/* MOBILE COMPONENT */

.secondPartMobile {
    height: 125px;
}

.secondPartMobile:hover {
    height: 125px;
}

.mobileTopWrapper {
    margin-top: var(--margin-header);
    margin-left: var(--margin-header);
    margin-right: var(--margin-header);
    display: flex;
    justify-content: space-between;
}

.mobileTopWrapper p {
    cursor: pointer;
}


.videoInfos div{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: small;
}

.videoInfos div:last-child {
    color: var(--grey);
    font-size: x-small;
}

.headerMobile {
    color: black;
    position: fixed;
    top: 50px;
}

.mobileVideoWrapper {
    margin: auto;
    height: 30vh;
    max-width: 80%;
}


@media (max-width: 930px) {
    .page {
        background-color: var(--white);
    }
    .fullScreenIcon {
        transform: scale(0.5);
        bottom: 20px;
        right: 20px;
    }

    .mobileVideo {
        width: 100%;
        padding-top: 20px;
        padding-left: var(--margin-header);
        padding-right: var(--margin-header);
        overflow-x: hidden;
    }
    
    .mobileVideoWrapper {
        position: relative;
        max-width: 100%;
        padding-top: 56.25%;
        height: auto;
        overflow: hidden;
    }
    
    .mobileVideoWrapper > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .secondPartMobile {
        position: relative;
        height: auto;
        margin-bottom: 0;
    }
}

@media (max-width: 620px) {
    .mobileTopWrapper {
        margin-top: 0px;
    }
}