.slide {
    padding: 0px 5px ;
    cursor: pointer;
}

.container {
    margin-left: calc(var(--margin-header) - 8px);
    margin-right: calc(var(--margin-header) - 8px);
}

@media  (max-width: 920px) {
    .slider {
        height: 20rem;
    }
  
}

@media (max-width: 620px) {
    .slider {
        height: 19.8rem;
    }
    .slide {
        padding: 0px 2rem;
    }   
    .container {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}

.image.loaded {
    opacity: 1;
}

.description {
    margin: 4rem;
    font-size: 18px;
    text-align: center;
}

.services, .teams {
    display: flex;
    gap: 10rem;
}

.services {
    border-bottom: 1px solid var(--grey);
    padding-bottom: 1rem;
}

.detailsWrapper {
    margin-left: 4rem;
    margin-right: 4rem;
    margin-bottom: 4rem;
}

.teamsWrapper {
    width: 100%;
}

.columns {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--grey);
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.columns:last-child {
    border-bottom: none;
}

.column {
    width: 50%;
}

.teamMember {
    display: flex;
}

.teamMember .role {
    min-width: 15rem;
    margin-right: 4.5rem;
}

.services div {
    display: grid;
    grid-template-rows: repeat(2, 1fr); 
    gap: 0.2rem;
}

.services > div:nth-child(1) {
    display: grid;
    grid-template-rows: repeat(3, auto);
}

.services > div:nth-child(2) {
    display: grid;
    grid-template-rows: repeat(3, auto); 
}

.teams .detailsTitle{
    padding-top: 1rem;
}

.teams p, .detailsTitle {
    min-width: 12rem;
}

.services > div p, .teamMember p {
    display: inline-block;
    vertical-align: baseline;
    line-height: 1.5rem; 
}

.teamMember .name {
    margin-top: 0; 
}

.secondServices {
    margin-left: 5.8rem;
}

.legalLink {
    margin-bottom: 0px;
    color: black;
    text-decoration: none;
    font-size: 12px;
}

.termsContainer {
    margin-bottom: 0px;
    width: auto;
    text-align: center;
    margin: 0px 5%;
}

.termsContainer a {
    text-decoration: none;
}

.termsContainer a:hover {
    text-decoration: underline;
}

@media (max-width: 1200px) {
    .columns {
        flex-direction: column;
    }
    .column {
        width: 100%;
    }
}
@media (max-width: 980px) {
    .teams p, .detailsTitle {
        min-width: 5rem;
    }
}
@media (max-width: 900px) {
    .services, .teams {
        gap: 0rem;
    }
    .services > div {
        min-width: 10rem;
    }
    .firstServices {
        margin-right: 55px;
    }
    .teams > div {
        min-width: 10rem;
    }
    .description {
        font-size: small;
    }
}

@media (max-width: 740px) {
    .services, .teams {
        position: relative;
    }
    
    .services {
        padding-bottom: 1.5rem;
    }

    .teams {
        padding: 0px;
    }
    
    .detailsWrapper {
        font-size: smaller;
    }

    .teamMember .role, .firstServices {
        min-width: 11rem;
        margin-right: 2.5rem;
    }

    .secondServices {
        margin-left: 1rem;
    }
    .description, .detailsWrapper {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@media (max-width: 565px) {
    .services > div {
        min-width: 7rem;
    }
    .teams > div {
        min-width: 7rem;
    }

    .secondServices {
        margin-left: 4rem;
    }
    .detailsWrapper {
        font-size: x-small;
    }
    
}
@media (max-width: 495px) {
    .teamMember .role, .firstServices {
        min-width: 9.5rem;
        margin-right: 0rem;
    }
    .secondServices {
        margin-left: 3.8rem;
    }
    .services > div {
        min-width: 5.5rem;
    }
    .teams > div {
        min-width: 5.5rem;
    }
  
}
