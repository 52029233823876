.mainWrapper {
    display: flex;
    border-top: 1px solid var(--black);
    border-bottom: 1px solid var(--black);
    height: 100%; 
    overflow: hidden;
    z-index: -1;
    overflow-y: hidden;
}

.medias {
    width: 60%;
    height: 100%;
    border-right: 1px solid var(--black);
    transition: all ease-in-out 1s;
    background-color: black;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    box-sizing: border-box;
}

.videoWrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.list {
    width: 40%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    transition: all ease-in-out 1s;
    max-height: 100%; 
}

.line {
    display: flex;
    border-top: 1px solid var(--black);
    padding: 20px;
    margin-top: -1px;
    border-bottom: 1px solid var(--black);
    cursor: pointer;
    align-items: center;
}

.line p {
    flex: 1;
    padding: 3px;
}

.line p:first-child {
    font-weight: bold;
}

.line p:last-child {
    text-align: right;
}

.selectedLine {
    background-color: var(--black); 
    color: var(--white);
}

@media (max-width:930px) {
    .line p {
       font-size: x-small;
    }
}

@media (max-width: 760px) {
    .mainWrapper {
        flex-direction: column;
    }

    .videoWrapper {
        padding: 10px;
        height: 255px;
    }

    .medias {
        width: 100%;
        background-color: white;
        border-bottom: 1px solid var(--black);
    }

    .line p {
        font-size: 11px;
    }
    
    .line p:nth-child(2){
        text-align: center;
    }

    .list {
        width: 100%;
    }

    .filter {
        font-size: smaller;
    }

    .projectLink {
        display: none;
    }
}