.dropdownContainer {
    position: relative;
    width: 50px;
}

.dropdownContainerWhite{
    color: var(--white);
}

.triangle.down {
    border-top: 5px solid var(--black);
}

.triangle.up {
    border-bottom: 5px solid var(--black);
}

.triangle.down.triangleWhite {
    border-top: 5px solid var(--white);
}

.triangle.up.triangleWhite {
    border-bottom: 5px solid var(--white);
}

.selectedLanguage {
    cursor: pointer;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    max-height: 150px;
    overflow-y: auto;
}

.option {
    border-top: 1px solid var(--black);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.optionWhite{
    border-top: 1px solid var(--white);
}

.triangle {
    position: absolute;
    top: 45%;
    right: 10px;
    width: 0;
    height: 0;
    margin-left: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transition: transform 0.3s ease;
}

@media (max-width: 540px) {
    .triangle {
        right: -5px;
    }
}

@media (max-width: 620px) {
    .triangle {
        right: 10px;
    }
    .dropdownContainer {
        position: relative;
        width: 40px;
        left: 1.7rem;
        top: -2.45rem;
        font-size: smaller;
    }

}
