
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.banner {
  width: fit-content;
  text-align: center;
  color: var(--white);
  filter: drop-shadow(0.3px 0px 0.1px black);
  height: 4rem;
  cursor: pointer;
}

.bannerMobile {
  color: var(--black);
  height: 1.4rem;
}

.bannerArrow {
  rotate: 270deg;
  transform: scale(0.5);
  position: relative;
  top: -30px;
}

.visualsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.visualsFirstRow, .visualsSecondRow {
  display: flex;
  width: 100%;
  height: 80vh;
  transform: scale(1.01);
}

.visualFirstChildContainer, .visualSecondChildContainer {
  width: 50%;
  height: 100%;
}

.videoWrapper, .imageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: scale(1.01);
  position: relative;
}

.video, .image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.singleMediaRow {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.singleMediaWrapper {
  width: 100%;
  height: 100%;
}

.singleMedia {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fadeIn {
  animation: fadeIn 1s ease-out forwards;
}

.spinnerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 930px) {
  .visualsFirstRow, .visualsSecondRow {
    height: 30vh;
  }
}

@media (max-width: 930px) and (orientation: landscape) {
  .visualsFirstRow, .visualsSecondRow {
    height: 70vh;
  }
}

.hidden {
  opacity: 0;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}