.homePage {
  width: 100vw;
  height: 105vh;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}

.videoContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.backgroundVideo {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  max-width: none;
  max-height: none;
  object-fit: cover;
}

/* CHOICE BETWEEN MBM OR DROP SHADOW */

.muteIcon, .fullScreenIcon, .seeMore {
  color: white;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}

.fullScreenIcon {
  width: 29px;
  height: 29px;
  transform: translateY(12px);
}

.content {
  position: fixed;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  width: 8rem;
}

.muteIcon {
  background-color: transparent;
  padding: 10px;
  font-size: 24px;
}

.buttonWrapper {
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
  left: 0;
  right: 0;
  bottom: 3.8rem;
  margin: auto;
}

.logoImg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
}

.logoImg:hover {
  border-bottom: 1px solid var(--white);
}

.muteButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(35px);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(35px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDownReverse {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-35px);
  }
}

@keyframes fadeInUpReverse {
  0% {
    opacity: 0;
    transform: translateY(-35px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.seeMore {
  text-decoration: none;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
  animation-duration: 0.5s;
  display: inline-block;
}

/* // FROM BOTTOM  */
.seeMore.visible {
  animation-name: fadeInUpReverse;
  opacity: 1;
}

.seeMore.hidden {
  animation-name: fadeOutDownReverse;
  animation-fill-mode: forwards;
}

.buttonWrapper {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.buttonWrapper.visible {
  animation-name: fadeInUp;
  opacity: 1;
}

.buttonWrapper.hidden {
  animation-name: fadeOutDown;
  animation-fill-mode: forwards;
  opacity: 0;
}

@media screen and (max-width: 930px) {
  .buttonWrapper, .content {
    bottom: -0.2rem;
    top: auto;
  }

  .muteIcon {
    position: relative;
    top: -0.95rem;
  }
  .buttonWrapper {
    transform: scale(0.8);
  }

  .fullScreenIcon {
    width: 20px;
    height: 20px;
    transform: translateY(-2px);
  }

  .muteIcon {
    padding: 10px;
    font-size: 18px;
  }
}

