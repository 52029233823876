.showreelPage {
    width: 100%;
}

.videoList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0;
    margin-left: var(--margin-header);
    margin-right: var(--margin-header);
    align-items: center;
}

.videoWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.videoWrapper iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    background: #000;
}

.videoTitle {
    margin-top: 1rem;
    font-size: 1.125rem;
    color: #333;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .videoWrapper {
        max-width: 100%;
    }
    
    .videoList {
        gap: 2rem;
    }
}