@font-face {
    font-family: 'agipo';
    src: url("./assets/fonts/agipo-regular.woff2") format("woff2"),
         url("./assets/fonts/agipo-regular.woff") format("woff"),
         url("./assets/fonts/agipo-regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'agipo';
    src: url("./assets/fonts/agipo-bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

:root {
    --grey: rgba(146, 146, 146, 0.8);
    --white: white;
    --black: black;
    --agipo: agipo, sans-serif;
    --mix-bm: difference;
    --margin-header: 1.8rem;
    --margin-header-mobile: 0.8rem;
    --margin-top-header: 2rem;
    --transition-duration: 700ms;
    --contact-input-font-size: 0.75rem;
    --scrollbar-bg: #f1f1f1; /* Scrollbar background color */
    --scrollbar-thumb: #888; /* Scrollbar thumb color */
    --scrollbar-thumb-hover: #555; /* Scrollbar thumb hover color */
}

* {
    scrollbar-width: none; 
}

html, body, #root, .App {
    margin: 0;
    padding: 0;
    font-family: var(--agipo);
    height: 100%;
    width: 100%;
}

body {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.App {
    position: relative;
}

.page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background: var(--white);
    will-change: transform, opacity;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
}

.active {
    font-weight: 900;
    font-synthesis: weight;
}
