.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height */
}

.mainWrapper {
    flex: 1; 
    display: flex;
    margin-left: var(--margin-header);
    margin-right: var(--margin-header);
    margin-bottom: 0rem;
    margin-top: 4rem;
    min-height: fit-content;
}

.leftPart {
    width: 31rem;
    margin-right: 6rem;
}

.inTouch {
    margin-right: 4rem;
    width: 100%;
}

.rightPart {
    width: 33%;
}

.input {
    border: none; 
    border-bottom: 1px solid var(--grey); 
    width: 100%;
    font-size: var(--contact-input-font-size);
    outline: none;
    padding: 0.5rem 0rem;
    box-shadow: none;
}

.section {
    display: flex;
    border-bottom: 1px solid var(--grey);
}

.sectionTitle {
    color: var(--grey);
    width: 35%;
}

.newsletter {
    width: 100%;
    margin-top: 5rem;
    margin-right: 4rem;
}

.form {
    position: relative;
}

.arrowButton {
    background: none;
    border: none;
    position: absolute;
    right: 0rem;
    bottom: 0px;
}

.arrow {
    height: 1.5rem; 
}

.detailsWrapper p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--contact-input-font-size);
}

.inTouch > :nth-child(2) {
    padding-bottom: 2rem;
}

.inTouch > :last-child {
    margin-top: 2rem;
    border-bottom: none;
}

.inTouch h2, .newsletter h2 {
    margin-bottom: 20px;
    font-size: 1.2rem;
}

.links {
    margin-top: auto; /* Positions links at the bottom */
    text-align: right;
    padding: 10px;
}

.links a img {
    height: 30px;
    margin: 0 5px;
}


@media (max-width: 950px) {
    .mainWrapper {
        margin: 0 4rem;
        margin-top: 4rem;
    }
}
/* Responsive styles */
@media (max-width: 830px) {
    .mainWrapper {
        flex-direction: column;
        margin: 0 4rem;
        margin-top: 4rem;
    }
    .rightPart {
        margin-top: 4rem;
        width: 100%;
        margin-bottom: 6rem;
    }
    .leftPart {
        width: 100%;
    }
}

@media (max-width: 620px) {
    .titleWrapper {
        position: relative;
        top: -20px;
    }
}

@media (max-width: 480px) {
    .section {
        flex-direction: column;
    }
    .sectionTitle {
        margin-bottom: 0.6rem;
    }
    .mainWrapper {
        font-size: small;
    }
}

@media (max-width: 430px) {    
    h2 {
        font-size: small;
    }

    .mainWrapper {
        margin-top:0rem;
        margin-left: var(--margin-header);
        margin-right: var(--margin-header);
    }

    .links {
        margin-top: 3rem;
    }

    .newsletter {
        margin-top: 2.4rem;
    }

    .rightPart {
        margin-top: 1rem;
    }

    .input {
        font-size: small;
        padding: 5px;
    }

    .inTouch > :nth-child(2) {
        padding-bottom: 1rem;
    }
    
    .inTouch > :last-child {
        margin-top: 1rem;
        border-bottom: none;
    }
    .inTouch h2, .newsletter h2 {
        margin-bottom: 0.6rem;
    }
}
