.workPage {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full height of the viewport */
    position: relative;
    margin: 0;
    padding: 0;
}

.contentWrapper {
    flex: 1;
    position: relative;
    
}

.contentWrapper.listView {
    overflow: hidden;
}


.mosaicSmallView {
    overflow: visible !important;
}

.mosaicSmallView .mosaicContent {
    padding-bottom: 20px;
    overflow-y: hidden;
}

.worklistFiltersWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.worklistFiltersWrapper::-webkit-scrollbar {
    display: none;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.toggleBtn {
    position: relative;
    top: 12px;
    border: none;
    background-color: var(--white);
    cursor: pointer;
    padding: 0;
    left: calc(var(--margin-header) - 4px) ;
    transition: all ease-in-out 0.5s;
}

.toggleBtn img {
    height: 24px;
    display: block;
}

.gridIcon img {
    height: 24px;
}

.filterBtn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: black;
    background: none;
    border: none;
    cursor: pointer;
    width: fit-content;
    font-size: large;
    margin-left: var(--margin-header);
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 0;
}

.selectedFilter {
    font-size: large;
}

.filterContainer {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid black;
    padding-top: 0.5rem;
    padding-left: 1.2rem;
}

.filter {
    border: 1px solid black;
    border-radius: 17px;
    margin: 10px 10px 0 10px;
    padding: 5px 10px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.selected {
    background-color: var(--black);
    color: var(--white);
}

.mosaicWrapper::-webkit-scrollbar {
    display: none;
}

.mosaicWrapper.loaded {
    opacity: 1;
    transform: translateY(0);
}

.scrollBackBtn {
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.2rem 0.3rem;
    border-radius: 110px;
    border: none;
    background-color: var(--black);
    color: var(--white);
    opacity: 0.4;
    transition: all 0.5s;
    cursor: pointer;
}

.scrollBackBtn img {
    transform: rotate(90deg);
    height: 35px;
}

.scrollBackBtn:hover {
    transform: translateX(-50%) scale(1.1);
}

.hidden {
    display: none;
}

.notVisible {
    opacity: 0;
    translate: 0px 15px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media (max-width: 950px) {
    .buttonsWrapper {
        top: 7.7rem;
    }
}

@media (max-width: 620px) {
    .buttonsWrapper {
        top: 4.5rem;
    }

    .titleWrapper {
        align-items: center;
    }
}

@media (max-width: 430px) {
    .scrollBackBtn  img{
        height: 25px;
    }
    .scrollBackBtn {
        padding: 0rem 0.6rem;
    }
}


@media (max-height: 530px) and (min-width: 620px) {
    .buttonsWrapper {
        top: 6.3rem;
    }
}
