.title {
    font-family: var(--agipo);
    margin-bottom: 21px;
    font-size: 1.2rem;
}

.form {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: var(--agipo);
}

.input {
    border: none; 
    border-bottom: 1px solid var(--grey); 
    padding: 0.2rem 0rem;
    font-size: var(--contact-input-font-size);
    outline: none; 
    margin-bottom: 0.85rem;
}

.submit {
    color: var(--white);
    background-color: var(--black);
    border-radius: 40px;
    border: none;
    width: fit-content;
    padding: 0.5rem 1rem;
    padding-right: 0.75rem;
    margin-top: 0.5rem;
    position: absolute;
    right: 0px;
    bottom: -3rem;
    cursor: pointer;
    font-size: large;
    display: flex;
    align-items: center;
}

.arrow {
    height: 1.5rem;
    margin-left: 0.5rem;
}

textarea {
    font-size: x-large;
    max-width: 100%;
    min-width: 100%;
    height: 1.5rem;
    min-height: 1.5rem;
}

.input::placeholder, textarea::placeholder {
    font-family: var(--agipo);
    font-size: var(--contact-input-font-size); 
}

@media (max-width: 430px) {    
    .input {
        font-size: small;
        padding: 5px;
        border-radius: 0px;
    }
    .input::placeholder {
        font-size: small;
    }
    textarea {
        height: 4rem;
    }
}