.videoContainer {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .videoPlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }
  
  .videoPlayer iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    min-width: 177.78vh; 
    min-height: 56.25vw; 
    transform: translate(-50%, -50%);
    border: none;
  }
 
  .fullscreenButton {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 20;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .fullscreenButton:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .muteIcon {
    transform: scale(1.5);
    position: relative;
    bottom: -8px;
    left: -6px;
    cursor: pointer;
}

.controlsContainer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    left: 0;
    right: 0;
    bottom: 4.2rem;
    margin: auto;
    width: 100%;
    z-index: 10000;
}

.controlsDesktop {
    color: white;
    filter: drop-shadow(0.3px 0px 0.1px black);
}

.controlsDesktop svg {
    cursor: pointer;
}