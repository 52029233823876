.content {
    margin-top: 4%;
    margin-bottom: 2%;
    margin-left: var(--margin-header);
    margin-right: 50%;
    text-align: justify;
}

.content:first-child {
    font-weight: bolder;
}

.bold {
    font-weight: bold;
}

@media (max-width: 930px) {
    .content {
        margin-right: var(--margin-header);
    }
}