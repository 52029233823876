:root {
    --gap: 1.2rem;
    --small-gap: 0.6rem;
}

.mosaic {
    display: grid;
    gap: var(--gap);
    margin-top: var(--gap);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.mosaic.visible {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 1200px) {
    .mosaic, 
    .mosaic .firstRowClass, 
    .mosaic .secondBlock, 
    .mosaic .leftPart, 
    .mosaic .thirdBlock, 
    .mosaic .thirdBlockRight, 
    .mosaic .fourthBlock,
    .mosaic .fifthBlock {
        gap: var(--small-gap);
    }
    .mosaic {
        margin-top: var(--small-gap);
    }
}

.firstRowClass {
    display: flex;
    justify-content: space-between;
    gap: var(--gap);
    height: 18vw;
}

/* Make mediaWrapper take full size of its container */
.mediaWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.mediaWrapper.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Make Media component fill the wrapper */
.mediaWrapper > div {  /* This targets the Suspense wrapper */
    width: 100%;
    height: 100%;
}

.mediaWrapper img, 
.mediaWrapper video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.secondBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gap);
}

.leftPart {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.thirdBlock {
    display: flex;
    gap: var(--gap);
}

.thirdBlockLeft {
    width: 50%;
}

.thirdBlockRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.fourthBlock {
    height: auto;
    max-height: 900px;
}

.fifthBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gap);
}