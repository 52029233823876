.title {
    text-align: right;
    font-size: 100px;
    margin-top: 1.5rem;
    margin-right: var(--margin-header);
    margin-bottom: 0px;
}

@media (max-width: 950px) {
    .title {
        font-size:  50px;
        margin-bottom: 5px;
        margin-top: 20px;
    }
}

@media (max-width: 620px) {
    .title {
        margin-right: var(--margin-header);
        padding-right: 0px;
        font-size:  30px;
    }
}

@media (max-height: 530px) {
    .title {
        margin-top: 0rem;
    }
}