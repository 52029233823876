.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--agipo);
    margin-left: var(--margin-header);
    margin-right: var(--margin-header);
    padding-top: var(--margin-top-header);
    z-index: 10;
    position: relative;
}

.logo {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.logo img {
    height: auto;
    width: 150px;
}

.header.white {
    filter: drop-shadow(0.3px 0px 0.1px black)
}

.navbar {
    list-style: none;
    display: flex;
    gap: 20px;
} 

.navbar li {
    display: inline;
}

.navbar li a {
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
    position: relative;
}

/* .navbar li a::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--black);
    transition: width 0.3s ease-in-out;
} */

/* .header.white .navbar li a::after {
    background-color: var(--white);
} */

.navbar li a:hover::after {
    width: 100%;
}

.header.white .navbar li a {
    color: var(--white);
    text-shadow: ();
}

.hamburger {
    position: absolute;
    right: 1rem;
    top: 0.4rem;
    transition: all ease-in-out 1s;
}

.hide {
    transform: translateX(130%);
    opacity: 0;
}

.move {
    transform: translateY(-150%);
}

.smallDropdown {
    list-style: none;
    position: absolute;
    right: 1rem;
    text-align: end;
    transition: all ease-in-out 0.5s;
    font-size: 0.9rem;
    width: max-content;
    top: 0.4rem;
}

.smallDropdown li a{
    text-decoration: none;
    
}

.smallDropdown li a p{
    color: black;
}

.header.white .smallNavbar .smallDropdown {
    mix-blend-mode: var(--mix-bm); 
}

.smallNavbar {
    display: none;
    position: relative;
    top: -15px;
    right: -10px;
}

.smallSwitcher {
    display: none;
    position: absolute;
    top: 50px;
    left: 107px;
}

.logoTxt {
    font-size: 30px;
    color: black;
}

.header.white .logoTxt {
    
    color: white;
    text-decoration: none;
    filter: drop-shadow(0px 0px 0.5px black);
}



@media (max-width: 620px) {
    .smallNavbar {
        display: block;
    }

    .smallSwitcher {
        display: block;
    }

    .navbar:first-child {
        color: red;
    }
    .navbar {
        display: none;
    }
    .header {
        margin: 5px;
        padding-top: 12px;
        padding-left: calc(var(--margin-header) - 8px);
        padding-right: calc(var(--margin-header) - 8px);
        margin-bottom: 2rem;
        transition: all ease-in-out 0.5s;
    }
    
    .logo img {
        width: 90px;
    }

    .opened {
        margin-bottom: 5rem;
    }
}

@media (max-width: 430px) {
    .hamburger {
        right: 10px;
    }
    .smallDropdown {
        right: 0.4rem;
    }
}

@media (max-height: 530px) {
    .header {
        padding-bottom: 0px;
    }
}
