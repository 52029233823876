
.media {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hidden {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.spinnerContainer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
}

.spinner {
  position: relative;
  top: 50%;
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}